import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
  loading: false,
  errors: [],
  generalSettings: [],
  generalCreated: false,
  generalUpdated: false,
  campaigns: [],
  campaignsSettings: [],
  usersContactEnquiry: [],
  newsletterSettingsList: [],
  newsletterUpdated: false,
  settingUpdateSuccess: false,
  databaseBackups: [],
  downloadDBLink: null,
};

const settingsStart = (state, action) => {
  return updateObject(state, {
    errors: null,
    loading: false,
    generalCreated: false,
    generalUpdated: false,
  });
};

const settingsFail = (state, action) => {
  return updateObject(state, {
    errors: action.error,
    loading: false,
    settingUpdateSuccess: false,
  });
};

const getSettings = (state, action) => {
  return updateObject(state, {
    generalSettings: action.response,
    settingUpdateSuccess: false,
  });
};

const createSettings = (state, action) => {
  return updateObject(state, {
    generalCreated: true,
    settingUpdateSuccess: true,
  });
};

const updateSettings = (state, action) => {
  return updateObject(state, {
    generalUpdated: true,
    settingUpdateSuccess: true,
  });
};

const getCampaigns = (state, action) => {
  return updateObject(state, {
    campaigns: action.response,
    campaignsSettings: action.response,
  });
};

const getAllUsersContactEnquiries = (state, action) => {
  return updateObject(state, {
    usersContactEnquiry: action.response,
  });
};

const getNewsletterSettings = (state, action) => {
  return updateObject(state, {
    newsletterSettingsList: action.response,
    newsletterUpdated: false,
  });
};

const updateNewsletterSettings = (state, action) => {
  return updateObject(state, {
    newsletterUpdated: true,
  });
};

const getDatabaseBackup = (state, action) => {
  return updateObject(state, {
    databaseBackups: action.response,
    downloadDBLink: null,
  });
};

const downloadDatabaseBackup = (state, action) => {
  return updateObject(state, {
    downloadDBLink: action.response,
  });
};

const clearDatabaseBackup = (state, action) => {
  return updateObject(state, {
    downloadDBLink: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETTINGS_START:
      return settingsStart(state, action);
    case actionTypes.SETTINGS_FAIL:
      return settingsFail(state, action);
    case actionTypes.GET_GENERAL_SETTINGS_SUCCESS:
      return getSettings(state, action);
    case actionTypes.CREATE_GENERAL_SETTINGS_SUCCESS:
    case actionTypes.CREATE_CAMPAIGN_SETTINGS_SUCCESS:
      return createSettings(state, action);
    case actionTypes.UPDATE_GENERAL_SETTINGS_SUCCESS:
    case actionTypes.UPDATE_CAMPAIGN_SETTINGS_SUCCESS:
      return updateSettings(state, action);
    case actionTypes.GET_CAMPAIGN_SETTINGS_SUCCESS:
      return getCampaigns(state, action);
    case actionTypes.GET_ALL_USERS_CONTACT_ENQUIRY_SUCCESS:
      return getAllUsersContactEnquiries(state, action);
    case actionTypes.NEWSLETTER_SETTINGS_SUCCESS:
      return getNewsletterSettings(state, action);
    case actionTypes.UPDATE_NEWSLETTER_SETTINGS_SUCCESS:
      return updateNewsletterSettings(state, action);
    case actionTypes.GET_DATABASE_BACKUP_SUCCESS:
      return getDatabaseBackup(state, action);
    case actionTypes.DOWNLOAD_DATABASE_BACKUP_SUCCESS:
      return downloadDatabaseBackup(state, action);
    case actionTypes.CLEAR_DATABASE_BACKUP:
      return clearDatabaseBackup(state, action);
    default:
      return state;
  }
};

export default reducer;
