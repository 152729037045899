import { call, put } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import {
  CAMPAIGN_LIST_API,
  TRANSACTIONS_API,
  INVESTMENT_API,
  DOCUMENTS_API,
  CAMPAIGN_ACTIVITY_API,
  REPAYMENT_API,
  DISTRIBUTION_API,
  STRIPE_ACH_PAYMENT_TOKEN_API,
  STRIPE_ACH_PAYMENT_CONFIRMATION_ID_API,
  CAMPAIGN_SHARE_CERTIFICATE_API,
  SECONDARY_MARKET_TRADE_UPDATE_API,
} from "../../actions/apiCollections";
import * as campaignsAction from "../../actions/campaigns/campaignsAction";
import {
  getDashboardPendingCampaignsSaga,
  getDashboardCountSaga,
} from "../dashboard/dashboardSaga";

export function* getCampaignsSaga(action) {
  yield put(campaignsAction.isLoading(true));

  try {
    const response = yield axios.get(CAMPAIGN_LIST_API + "admin", {
      params: { limit: 10 * action.pageLimit },
    });
    yield put(campaignsAction.getCampaignsSuccess(response.data.data.data));
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* getReservationsSaga(action) {
  yield put(campaignsAction.campaignStart());
  try {
    const response = yield axios.get(
      TRANSACTIONS_API + action.id + "/totalReservations"
    );
    yield put(campaignsAction.getReservationsSuccess(response.data.data.data));
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

/**
 * Method to call `OverviewTab.js` details
 * @param {any} action
 */
export function* getCampaignDetailsSaga(action) {
  yield put(campaignsAction.isLoading(true));
  try {
    const response = yield axios.get(
      CAMPAIGN_LIST_API + action.id + "/campaign-detail"
    );
    yield put(
      campaignsAction.getCampaignDetailsSuccesss(response.data.data.data)
    );
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* deleteCampaignSaga(action) {
  try {
    yield axios.delete(CAMPAIGN_LIST_API + action.id + "/campaign-detail");
    const obj = {
      pageLimit: 1,
    };
    yield call(getCampaignsSaga, obj);
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* getInvestorsSaga(action) {
  yield put(campaignsAction.isLoading(true));
  try {
    const staus = action?.status;
    const response = yield axios.get(
      INVESTMENT_API + action.campaignId + "/admin",
      staus
    );
    yield put(campaignsAction.getInvestorsSuccess(response.data.data.data));
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* getDocumentsSaga(action) {
  yield put(campaignsAction.isLoading(true));

  try {
    const response = yield axios.get(DOCUMENTS_API + action.id + "/documents");
    yield put(campaignsAction.getDocumentsSuccess(response.data.data.data));
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* uploadDocumentContractSaga(action) {
  yield put(campaignsAction.campaignStart());
  try {
    let id = new FormData();
    id = action.contractDocument;
    id = id.get("id");
    yield axios.patch(
      DOCUMENTS_API + id + "/contract",
      action.contractDocument
    );
    var object = {};
    action.contractDocument.forEach(function (value, key) {
      object[key] = value;
    });
    yield call(getCampaignDetailsSaga, object);
    yield call(getDocumentsSaga, object);
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}
export function* approveRepayment(action) {
  yield put(campaignsAction.campaignStart());
  yield put(campaignsAction.isLoading(true));
  try {
    yield axios.post(CAMPAIGN_LIST_API + action.id + "/confirm-payout", action);
    yield call(getCampaignDetailsSaga, action);
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
    yield put(campaignsAction.isLoading(false));
  }
}

export function* approveCampaignSaga(action) {
  yield put(campaignsAction.campaignStart());
  try {
    yield axios.patch(
      CAMPAIGN_LIST_API + action.id + "/campaign-detail",
      action
    );
    const obj = {
      id: 0,
    };
    const all = {
      pageLimit: 1,
    };
    yield call(getDashboardPendingCampaignsSaga, obj);
    yield call(getCampaignsSaga, all);
    yield call(getDashboardCountSaga);
    yield call(getCampaignDetailsSaga, action);
    yield call(getReservationsSaga, action);
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* pendingCampaignSaga(action) {
  yield put(campaignsAction.campaignStart());
  try {
    yield axios.patch(
      CAMPAIGN_LIST_API + action.id + "/campaign-detail",
      action
    );
    const obj = {
      id: 1,
    };
    const all = {
      pageLimit: 1,
    };
    yield call(getDashboardPendingCampaignsSaga, obj);
    yield call(getCampaignsSaga, all);
    yield call(getDashboardCountSaga);
    yield call(getCampaignDetailsSaga, action);
    yield call(getReservationsSaga, action);
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* declineCampaignSaga(action) {
  yield put(campaignsAction.campaignStart());
  try {
    yield axios.patch(
      CAMPAIGN_LIST_API + action.id + "/campaign-detail",
      action
    );
    const obj = {
      id: 1,
    };
    const all = {
      pageLimit: 1,
    };
    yield call(getDashboardPendingCampaignsSaga, obj);
    yield call(getCampaignsSaga, all);
    yield call(getDashboardCountSaga);
    yield call(getCampaignDetailsSaga, action);
    yield call(getReservationsSaga, action);
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* featureCampaignSaga(action) {
  try {
    const response = yield axios.patch(
      CAMPAIGN_LIST_API + action.id + "/campaign-detail",
      action
    );
    const obj = {
      status: "All",
    };
    yield call(getCampaignsSaga, obj);
    yield call(getCampaignDetailsSaga, action);
    yield put(campaignsAction.featureCampaignSuccess(response.data.data.data));
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* unfeatureCampaignSaga(action) {
  yield put(campaignsAction.campaignStart());
  try {
    const response = yield axios.patch(
      CAMPAIGN_LIST_API + action.id + "/campaign-detail",
      action
    );
    const obj = {
      pageLimit: 1,
    };
    yield call(getCampaignsSaga, obj);
    yield call(getCampaignDetailsSaga, action);
    yield put(campaignsAction.featureCampaignSuccess(response.data.data.data));
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* getInvestorDetailsSaga(action) {
  try {
    const response = yield axios.get(INVESTMENT_API + action.id + "/details");
    yield put(
      campaignsAction.getInvestorDetailsSuccess(response.data.data.data)
    );
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* getCampaignActivitiesSaga(action) {
  yield put(campaignsAction.isLoading(true));
  try {
    const response = yield axios.get(CAMPAIGN_ACTIVITY_API + action.campaignId);
    yield put(
      campaignsAction.getCampaignActivitiesSuccess(response.data.data.data.docs)
    );
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* getRepaymentInSaga(action) {
  yield put(campaignsAction.isLoading(true));
  try {
    const response = yield axios.get(`${REPAYMENT_API}${action.id}`);
    yield put(campaignsAction.getRepaymentInSuccess(response.data.data.data));
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* createRepaymentInSaga(action) {
  yield put(campaignsAction.isLoading(true));
  try {
    yield axios.post(
      `${REPAYMENT_API}${action.repaymentData.id}`,
      action.repaymentData
    );
    yield call(getRepaymentInSaga, action.repaymentData);
    yield call(getDistributionSaga, action.repaymentData);
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* updateRepaymentSaga(action) {
  try {
    yield axios.patch(`${REPAYMENT_API}${action.repaymentId}`, action);
    yield call(getRepaymentInSaga, action);
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* getDistributionSaga(action) {
  yield put(campaignsAction.isLoading(true));
  try {
    const response = yield axios.get(`${DISTRIBUTION_API}${action.id}`);
    yield put(campaignsAction.getDistributionSuccess(response.data.data.data));
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}
export function* createDistributionSaga(action) {
  try {
    yield axios.post(
      `${DISTRIBUTION_API}${action.distributionData.id}`,
      action.distributionData
    );
    yield call(getDistributionSaga, action.distributionData);
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* getDistributionDetailSaga(action) {
  yield put(campaignsAction.campaignStart());
  try {
    const response = yield axios.get(
      `${DISTRIBUTION_API}${action.distributionId}/get-detail`
    );
    yield put(
      campaignsAction.getDistributionDetailSuccess(response.data.data.data)
    );
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* deleteDistributionRecordSaga(action) {
  yield put(campaignsAction.isLoading(true));
  try {
    yield axios.delete(
      `${DISTRIBUTION_API}${action.distributionData.distributionId}`
    );
    yield call(getDistributionSaga, action.distributionData);
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* updateDistributionSaga(action) {
  try {
    yield axios.patch(
      `${DISTRIBUTION_API}${action.distributionData.distributionId}`,
      action.distributionData
    );
    yield call(getDistributionSaga, action.distributionData);
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* updateDistributionAdjustmentSaga(action) {
  try {
    yield axios.patch(
      `${DISTRIBUTION_API}${action.distributionData.distributionDetailId}/distribution-detail`,
      action.distributionData
    );
    yield call(getDistributionDetailSaga, action.distributionData);
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* updateDistributionMarkDistributed(action) {
  try {
    yield axios.patch(
      `${DISTRIBUTION_API}mark-distributed`,
      action.distributionData
    );
    yield call(getDistributionDetailSaga, action.distributionData);
    yield call(getDistributionSaga, action.distributionData);
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* investActionSaga(action) {
  const { investData } = action;
  try {
    const response = yield axios.patch(
      TRANSACTIONS_API + investData.transactionId,
      investData
    );
    yield put(campaignsAction.investActionResponse(response.data.data.data));
    yield call(getInvestorsSaga, investData);
    // yield call(getInvestorDetailsSaga, investData);
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* acknowledgeDocumentSaga(action) {
  const { accredetionData } = action;
  try {
    const response = yield axios.patch(
      INVESTMENT_API + accredetionData.id + "/details",
      accredetionData
    );
    yield put(
      campaignsAction.acknowledgeDocumentResponse(response.data.data.data)
    );
    yield call(getInvestorsSaga, accredetionData);
    yield call(getInvestorDetailsSaga, accredetionData);
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* updateCampaignSaga(action) {
  const { campaignData } = action;
  yield put(campaignsAction.isLoading(true));
  try {
    const response = yield axios.patch(
      CAMPAIGN_LIST_API + campaignData.id + "/campaign-detail",
      campaignData
    );
    yield put(campaignsAction.updateCampaignSuccess(response.data.data.data));
    yield call(getCampaignDetailsSaga, campaignData);
    const admin = {
      pageLimit: 1,
    };
    yield call(getCampaignsSaga, admin);
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.isLoading(false));
    console.log(error);
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* getSearchCampaignsSaga(action) {
  try {
    const response = yield axios.get(CAMPAIGN_LIST_API + "admin", {
      params: {
        ...(action.response.campaign !== "" && {
          campaign: action.response.campaign,
        }),
        ...(action.response.category !== undefined && {
          category: action.response.category,
        }),
        ...(action.response.status !== undefined &&
          action.response.status !== "" && {
            status: action.response.status,
          }),
        ...(action.response.user !== "" && {
          user: action.response.user,
        }),
        ...(action.pageLimit !== "" && {
          limit: 10 * action.pageLimit,
        }),
      },
    });
    yield put(campaignsAction.getCampaignsSuccess(response.data.data.data));
  } catch (error) {
    yield put(campaignsAction.campaignFail(error));
  }
}

export function* getStripeACHPaymentTokenSaga() {
  yield put(campaignsAction.clearStripeACHPaymentToken());
  yield put(campaignsAction.isLoading(true));
  try {
    const response = yield axios.get(STRIPE_ACH_PAYMENT_TOKEN_API);
    yield put(
      campaignsAction.getStripeACHPaymentTokenSuccess(response.data.data.data)
    );
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.campaignFail(error.response));
    yield put(campaignsAction.isLoading(false));
  }
}

export function* postStripeACHPaymentConfimationSaga(action) {
  yield put(campaignsAction.isLoading(true));
  try {
    const response = yield axios.post(
      `${STRIPE_ACH_PAYMENT_CONFIRMATION_ID_API}?lng=en`,
      action.data
    );
    yield put(
      campaignsAction.postStripeACHPaymentConfimationSuccess(
        response.data.data.data
      )
    );
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.isLoading(false));
    yield put(campaignsAction.campaignFail(error.response.data.message));
  }
}

export function* generateCampaignShareCertificateSaga(action) {
  yield put(campaignsAction.isLoading(true));
  try {
    const response = yield axios.post(
      `${CAMPAIGN_SHARE_CERTIFICATE_API}?lng=en`,
      { campaignId: action.campaignId, createdAt: Date.now() }
    );
    yield put(
      campaignsAction.generateCampaignShareCertificateSuccess(
        response.data.data.data
      )
    );
    const admin = {
      campaignId: action.campaignId,
    };
    yield call(campaignShareCertificateListSaga, admin);
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.isLoading(false));
    yield put(campaignsAction.campaignFail(error.response.data.message));
  }
}

export function* campaignShareCertificateListSaga(action) {
  yield put(campaignsAction.isLoading(true));
  try {
    const response = yield axios.get(
      `${CAMPAIGN_SHARE_CERTIFICATE_API}${action.campaignId}?lng=en`
    );
    yield put(
      campaignsAction.campaignShareCertificateListSuccess(
        response.data.data.data
      )
    );
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.isLoading(false));
    yield put(campaignsAction.campaignFail(error.response.data.message));
  }
}

export function* campaignShareCertificateDistributionSaga(action) {
  yield put(campaignsAction.isLoading(true));
  try {
    yield axios.patch(
      `${CAMPAIGN_SHARE_CERTIFICATE_API}distribute?lng=en`,
      action.certificateIds
    );

    const admin = {
      campaignId: action.certificateIds.campaignId,
    };

    yield call(campaignShareCertificateListSaga, admin);
    yield put(campaignsAction.isLoading(false));
  } catch (error) {
    yield put(campaignsAction.isLoading(false));
    yield put(campaignsAction.campaignFail(error.response.data.message));
  }
}
