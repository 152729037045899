import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "../store/reducers";
import createSagaMiddleware from "redux-saga";
import {
  watchAuth,
  watchAdmins,
  watchCategory,
  watchManageOptions,
  watchFrontEndUsers,
  watchMisc,
  watchCountries,
  watchSiteSettings,
  watchContentPage,
  watchAPIManagement,
  watchRevenue,
  watchRoles,
  watchMeta,
  watchTaxomony,
  watchSpamSetting,
  watchDashboard,
  watchCampaigns,
  watchAccreditation,
  watchActivities,
  watchEmailSettings,
  watchExportCSVList,
  watchWallet,
  saveInvestment,
  watchMangopay,
  userInvestment,
  watchNotificationTemplates,
  watchSecondaryMarket,
} from "../store/sagas";

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware, sagaMiddleware))
);
const persistor = persistStore(store);
sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchAdmins);
sagaMiddleware.run(watchCategory);
sagaMiddleware.run(watchManageOptions);
sagaMiddleware.run(watchFrontEndUsers);
sagaMiddleware.run(watchMisc);
sagaMiddleware.run(watchCountries);
sagaMiddleware.run(watchSiteSettings);
sagaMiddleware.run(watchContentPage);
sagaMiddleware.run(watchAPIManagement);
sagaMiddleware.run(watchRevenue);
sagaMiddleware.run(watchRoles);
sagaMiddleware.run(watchMeta);
sagaMiddleware.run(watchTaxomony);
sagaMiddleware.run(watchSpamSetting);
sagaMiddleware.run(watchDashboard);
sagaMiddleware.run(watchCampaigns);
sagaMiddleware.run(watchAccreditation);
sagaMiddleware.run(watchActivities);
sagaMiddleware.run(watchEmailSettings);
sagaMiddleware.run(watchExportCSVList);
sagaMiddleware.run(watchWallet);
sagaMiddleware.run(saveInvestment);
sagaMiddleware.run(watchMangopay);
sagaMiddleware.run(userInvestment);
sagaMiddleware.run(watchNotificationTemplates);
sagaMiddleware.run(watchSecondaryMarket);

export default store;
export { persistor };
